import React from 'react';
import { ColumnDefinition } from '@amzn/dots-core-ui';
import Link from '@amzn/meridian/link';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { Device } from '$/api';
import { CoreDateFromNow } from '$/components';
import { createDeviceDetailUri, createPoolDetailUri } from '$/features/routes';
import { environment } from '$/environments';
import { DeviceStatusLabel } from './DeviceStatusLabel';

export const DeviceColumnDefinitions: ColumnDefinition<Device>[] = [
  {
    id: 'dsn',
    header: 'Details',
    item: ({ id, dsn, devicePools, createdBy, description }): JSX.Element => (
      <Column spacing="300">
        {dsn && (
          <Link href={createDeviceDetailUri(environment.schedulerUrl, id)}>
            {dsn}
          </Link>
        )}
        {devicePools?.map((pool) => (
          <Link
            key={pool.id}
            href={createPoolDetailUri(environment.schedulerUrl, pool?.id ?? '')}
          >
            Pool: {pool.name}
          </Link>
        ))}
        <Text>Owner: {createdBy ?? 'no one'}</Text>
        {description && <Text>Description: {description}</Text>}
      </Column>
    ),
  },
  {
    id: 'status',
    header: 'Status',
    item: ({ status, statusModified }): JSX.Element => (
      <Column spacing="300">
        <DeviceStatusLabel status={status} />
        {statusModified && <CoreDateFromNow date={statusModified} />}
      </Column>
    ),
  },
  {
    id: 'type',
    header: 'Type',
    item: ({
      type,
      build,
      softwareVersion,
      hardwareConfiguration,
    }): JSX.Element => (
      <Column spacing="300">
        <Text>
          <strong>{type?.name}</strong>
        </Text>
        {softwareVersion && <Text>Software Version: {softwareVersion}</Text>}
        {build?.type?.project?.name && (
          <Text>Platform: {build?.type?.project?.name}</Text>
        )}
        {build?.version && <Text>Build Version: {build.version}</Text>}
        {hardwareConfiguration?.name && (
          <Text>HW Config: {hardwareConfiguration?.name}</Text>
        )}
      </Column>
    ),
  },
];
