/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
  CoreFilterableSelect,
  CoreFilterableSelectProps,
  ValidationProps,
} from '@amzn/dots-core-ui';
import { useQuery } from 'react-query';
import Alert from '@amzn/meridian/alert';

import { useClient } from '$/api';

export type HostStageSelectProps = Pick<
  ValidationProps<CoreFilterableSelectProps>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export function HostStageSelect({
  validationState,
  ...rest
}: HostStageSelectProps): JSX.Element {
  const { hoststage } = useClient();
  const { data, isLoading, isError, error } = useQuery<string[], Error>(
    'agentstage',
    async () => {
      const response = await hoststage.getAll();
      return response.content.map((item) => item.stageName);
    }
  );
  const items = useMemo(() => data ?? [], [data]);
  if (isError) {
    return (
      <Alert type="error" title="Failed to get Host Agent Stages">
        {error?.message}
      </Alert>
    );
  }
  return (
    <CoreFilterableSelect
      {...rest}
      label="Host Stages"
      items={items}
      disabled={isLoading}
      validationState={isLoading ? 'validating' : validationState}
    />
  );
}
