import React from 'react';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';

import Tooltip from '@amzn/meridian/tooltip';
import { format, formatDistanceToNow } from 'date-fns';

export type CoreDateFromNowProps = {
  date: string;
};

export const CoreDateFromNow = ({
  date,
}: CoreDateFromNowProps): JSX.Element => {
  const d = new Date(`${date}Z`);
  return (
    <Tooltip position="bottom" title={format(d, 'MMMM dd, yyyy HH:mm:ss')}>
      <Text>{formatDistanceToNow(d, { addSuffix: true })}</Text>
    </Tooltip>
  );
};

export const CoreDate = ({ date }: CoreDateFromNowProps): JSX.Element => {
  const d = new Date(`${date}Z`);
  return (
    <Column>
      <Text>{format(d, 'MMMM dd, yyyy HH:mm:ss')}</Text>
      <Tooltip position="bottom" title={format(d, 'MMMM dd, yyyy HH:mm:ss')}>
        <Text>{formatDistanceToNow(d, { addSuffix: true })}</Text>
      </Tooltip>
    </Column>
  );
};
