import React, { useCallback } from 'react';
import Button from '@amzn/meridian/button';
import { ButtonProps } from '@amzn/meridian/button/button';
import { CoreModal, useToaster, useToggle } from '@amzn/dots-core-ui';
import { HostAgentStage, useClient } from '$/api';
import { HostStageForm } from './HostStageForm';

export type HostStageAddButtonProps = Pick<ButtonProps, 'size' | 'type'>;

export function HostStageAddButton({
  size,
  type,
}: HostStageAddButtonProps): JSX.Element {
  const { openToast } = useToaster();
  const { hoststage } = useClient();
  const [isOpen, close, open] = useToggle();

  const onSubmit = useCallback(
    async (stage: Partial<HostAgentStage>): Promise<void> => {
      hoststage.create(stage);
      openToast({
        title: 'Success!',
        message: `Created host agent stage.`,
        type: 'success',
      });
      close();
    },
    [close, openToast, hoststage]
  );
  return (
    <>
      <Button onClick={open} type={type} size={size}>
        Add new Stage
      </Button>
      <CoreModal
        title="Create a host agent stage"
        isOpen={isOpen}
        onClose={close}
      >
        <HostStageForm onCancel={close} onSubmit={onSubmit} />
      </CoreModal>
    </>
  );
}
