/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import {
  useToaster,
  CoreInput,
  CoreFormFooter,
  CoreFileInput,
  useForm,
} from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import { useClient } from '$/api';
import Alert from '@amzn/meridian/alert';

export function HostVersionAddPanel(): JSX.Element {
  const { hostversion } = useClient();
  const { openToast } = useToaster();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    releaseNotes: {
      initialValue: '',
    },
    versionName: {
      initialValue: '',
    },
    file: {
      initialValue: [],
      validate: (value: File[]): string =>
        value.length === 0 ? 'File is required' : '',
    },
  });

  const onSubmit = useCallback(
    async (input): Promise<void> => {
      try {
        hostversion.uploadVersion(
          input.versionName,
          input.releaseNotes,
          input.file
        );
        openToast({
          title: 'Success!',
          message: `The ${input.versionName} version was created`,
          type: 'success',
        });
      } catch (e: any) {
        // console.error(e);
        const errorCode = e?.response?.status || '???';
        const errorResponse = JSON.stringify(e?.response?.data || {});
        openToast({
          title: 'Failed!',
          message: `${errorCode}: ${errorResponse}`,
          type: 'error',
        });
      }
    },
    [hostversion, openToast]
  );
  return (
    <Column spacing="500">
      <Column spacingInset="400" type="outline">
        <Heading level={3}>Add Host Version</Heading>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Column spacingInset="200" alignmentHorizontal="stretch">
            {submitState.status === 'error' && (
              <Alert type="error" title="Error!" size="large">
                {submitState.error?.message}
              </Alert>
            )}
            <CoreInput
              label="Version Name"
              value={values.versionName}
              onChange={handlers.versionName}
              errorMessage={errors.versionName}
              validationState={states.versionName}
            />
            <CoreFileInput
              label="Version File"
              onChange={handlers.file}
              errorMessage={errors.file}
              validationState={states.file}
              disabled={isSubmitting}
            />
            <CoreFormFooter
              isConfirmDisabled={hasErrors || isSubmitting}
              isLoading={isSubmitting}
              onCancelClick={() => {}}
            />
          </Column>
        </form>
      </Column>
    </Column>
  );
}
