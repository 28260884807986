/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
  CoreFilterableSelect,
  CoreFilterableSelectProps,
  ValidationProps,
} from '@amzn/dots-core-ui';
import { useQuery } from 'react-query';
import Alert from '@amzn/meridian/alert';
import { useClient } from '$/api';

export type LabSelectProps = Pick<
  ValidationProps<CoreFilterableSelectProps>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export function LabSelect({
  validationState,
  ...rest
}: LabSelectProps): JSX.Element {
  const { labs } = useClient();
  const { data, isLoading, isError, error } = useQuery<string[], Error>(
    'labs',
    async () => {
      const response = await labs.getAll({ page: 0, pageSize: 0 });
      return response.content.map((item) => item.name);
    }
  );
  const items = useMemo(() => data ?? [], [data]);
  if (isError) {
    return (
      <Alert type="error" title="Failed to get Labs">
        {error?.message}
      </Alert>
    );
  }
  return (
    <CoreFilterableSelect
      {...rest}
      label="No Labs"
      items={items}
      disabled={isLoading}
      validationState={isLoading ? 'validating' : validationState}
    />
  );
}
