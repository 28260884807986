import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';
import { GroupApi, GroupClient } from './groups';
import { HostApi, HostClient } from './hosts';
import { DeviceApi, DeviceClient } from './devices';
import { LabApi, LabClient } from './labs';
import { TestrunApi, TestrunClient } from './testruns';
import {
  HostMgmtApi,
  HostVersionMgmtApi,
  HostStageMgmtApi,
  HostMgmtClient,
  HostVersionClient,
  HostStageClient,
} from './hostmgmt';

export interface HostAgentServiceApi {
  hosts: HostApi;
}

export interface SchedulingApi {
  groups: GroupApi;
  devices: DeviceApi;
  hostmgmt: HostMgmtApi;
  hostversion: HostVersionMgmtApi;
  hoststage: HostStageMgmtApi;
  labs: LabApi;
  testruns: TestrunApi;
}

export const createHostAgentServiceClient = (
  serverApiUrl: string,
  createToken: TokenCreator
): HostAgentServiceApi => {
  const client = new HttpClient(serverApiUrl, createToken);
  return {
    hosts: HostClient(client),
  };
};

export const createSchedulingClient = (
  serverApiUrl: string,
  createToken: TokenCreator
): SchedulingApi => {
  const client = new HttpClient(serverApiUrl, createToken);
  return {
    devices: DeviceClient(client),
    groups: GroupClient(client),
    hostmgmt: HostMgmtClient(client),
    hostversion: HostVersionClient(client),
    hoststage: HostStageClient(client),
    labs: LabClient(client),
    testruns: TestrunClient(client),
  };
};
