/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import { CoreInput, CoreFormFooter, useForm } from '@amzn/dots-core-ui';
import { HostAgentStage } from '$/api';
import { HostVersionNameSelect } from '$/components';

export type HostStageFormProps = {
  hostStage?: HostAgentStage;
  onCancel: () => void;
  onSubmit: (t: any) => void;
};

export function HostStageForm({
  hostStage,
  onCancel,
  onSubmit,
}: HostStageFormProps): JSX.Element {
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    stageName: {
      initialValue: hostStage?.stageName ?? '',
    },
    versionName: {
      initialValue: hostStage?.version ?? '',
    },
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="200" alignmentHorizontal="stretch">
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          disabled={!!hostStage}
          label="Stage Name"
          value={values.stageName}
          onChange={handlers.stageName}
          errorMessage={errors.stageName}
          validationState={states.stageName}
        />
        <HostVersionNameSelect
          value={values.versionName}
          onChange={handlers.versionName}
          errorMessage={errors.versionName}
          validationState={states.versionName}
        />

        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
}
