/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { formatDuration } from 'date-fns';
import Tooltip from '@amzn/meridian/tooltip';
import Text from '@amzn/meridian/text';
import { TextPropsWithRef } from '@amzn/meridian/text/text';

export type CoreDurationProps = {
  seconds: number;
} & Omit<TextPropsWithRef, 'children'>;

export function CoreDuration({
  seconds: inputSeconds,
  ...rest
}: CoreDurationProps): JSX.Element {
  const padLeft = (num: number): string => num.toString().padStart(2, '0');
  const hours = Math.floor(inputSeconds / 3600);
  const minutes = Math.floor((inputSeconds % 3600) / 60);
  const seconds = inputSeconds % 60;
  return (
    <Tooltip
      title={formatDuration(
        { hours, minutes, seconds },
        { format: ['hours', 'minutes', 'seconds'] }
      )}
      position="bottom"
    >
      <Text {...rest}>{`${padLeft(hours)}:${padLeft(minutes)}:${padLeft(
        seconds
      )}`}</Text>
    </Tooltip>
  );
}
