import React from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import { useClient } from '$/api';
import { CoreSearchTable } from '$/components';
import { HostStageColumnDefinitions, HostStageAddButton } from './components';

const filters = [
  { key: 'stage_name', label: 'Name', isDefault: true },
  { key: 'version', label: 'Version' },
];

export function HostStageListPanel(): JSX.Element {
  const { hoststage } = useClient();
  return (
    <Column spacing="500">
      <Column spacingInset="400" type="outline">
        <Heading level={3}>Host Stage</Heading>
        <Divider />
        <HostStageAddButton type="tertiary" />
        <CoreSearchTable
          resourceKey={hoststage.resourceKey}
          getAll={hoststage.getAll}
          resourceFilters={filters}
          columns={HostStageColumnDefinitions}
        />
      </Column>
    </Column>
  );
}
