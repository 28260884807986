import {
  CoreAuth,
  RemoteApplicationAdapter,
  RemoteLifecyleEventArgs,
  mountReactComponent,
  unmountReactComponent,
} from '@amzn/dots-core-ui';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Router } from 'react-router-dom';
import { ClientProvider } from './api';
import { App, AppNavigation, AppRouter } from './features';
import { environment } from './environments';

declare global {
  interface Window {
    HostAgentService: RemoteApplicationAdapter;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not trigger a refetch when the window re-focuses
      refetchOnWindowFocus: false,
      // don't retry failed requests, it probably won't help
      retry: 0,
    },
  },
});

window.HostAgentService = {
  mount: ({ containerId }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CoreAuth userManagerSettings={environment.userManagerSettings}>
            <ClientProvider
              searchApiUrl={environment.searchApiUrl}
              hostAgentServiceApiUrl={environment.hostAgentServiceApiUrl}
              schedulingApiUrl={environment.schedulingApiUrl}
            >
              <App />
            </ClientProvider>
          </CoreAuth>
        </BrowserRouter>
      </QueryClientProvider>,
      containerId
    ),
  mountRouter: ({
    containerId,
    history,
    createToken,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <CoreAuth userManagerSettings={environment.userManagerSettings}>
            <ClientProvider
              searchApiUrl={environment.searchApiUrl}
              hostAgentServiceApiUrl={environment.hostAgentServiceApiUrl}
              schedulingApiUrl={environment.schedulingApiUrl}
              createToken={createToken}
            >
              <AppRouter isRootApp={false} />
            </ClientProvider>
          </CoreAuth>
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  mountNavigation: ({
    containerId,
    history,
    isMenuOpen,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <CoreAuth userManagerSettings={environment.userManagerSettings}>
            <AppNavigation isMenuOpen={isMenuOpen} />
          </CoreAuth>
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  unmountRouter: unmountReactComponent,
  unmountNavigation: unmountReactComponent,
};
