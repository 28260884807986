/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, PaginatedResponse } from '@amzn/dots-core-ui';
import { environment } from '$/environments';
import {
  HostMgmt,
  HostAgentStage,
  HostVersion,
  HostVersionForm,
} from '../models';
import {
  BaseApi,
  BaseReadApi,
  BaseGetAllFilter,
  baseApiFactory,
  baseReadApiFactory,
} from '../baseApi';

// Host Management API. Retrieves host info with extra updater metadata
export type HostMgmtId = HostMgmt['id'];

export type HostMgmtGetAllFilter = BaseGetAllFilter<HostMgmtId> & {
  labs?: string[];
};

export type HostMgmtApi = BaseReadApi<
  HostMgmtId,
  HostMgmt,
  HostMgmtGetAllFilter
> & {
  update: (hostChangeSet: Partial<HostMgmt>[]) => Promise<void>;
};

export const HostMgmtClient = (client: HttpClient): HostMgmtApi => ({
  ...baseReadApiFactory(client, 'hostmgmt'),
  update: (hostChangeSet): Promise<void> =>
    client.put({
      url: `/hostmgmt/`,
      payload: { hostChangeSet: JSON.stringify(hostChangeSet) },
    }),
});

// Host Version Api. Manages individual host version files

export type HostVersionId = HostVersion['id'];

export type HostVersionGetAllFilter = BaseGetAllFilter<HostMgmtId>;

export type HostVersionMgmtApi = BaseApi<
  HostVersionId,
  HostVersion,
  HostVersionGetAllFilter
> & {
  uploadVersion: (
    vname: string,
    releaseNotes: string,
    file?: File[]
  ) => Promise<HostVersionForm>;
};

export const HostVersionClient = (client: HttpClient): HostVersionMgmtApi => ({
  ...baseApiFactory(client, 'hostversion'),
  uploadVersion: async (
    vname: string,
    releaseNotes: string,
    file?: File[]
  ): Promise<HostVersionForm> => {
    // const oldClient = new HttpClient(environment.oldSchedulingApiUrl, client.createToken);
    // HostVersionForm
    const formData = new FormData();
    if (file) {
      formData.append('file', file[0], file[0].name);
    }
    const headers = {
      'Content-Type': 'multipart/form-data',
    } as any;
    if (client.createToken) {
      const token = await client.createToken();
      headers.Authorization = `Bearer ${token}`;
    }
    const urlResponse = await client.request({
      url: `${environment.rootSchedulingApiUrl}/userapi/v3/updater_file`,
      method: 'post',
      headers,
      data: formData,
    });
    // Check successful upload
    await client.post({
      url: `/hostversion`,
      payload: {
        updaterFileId: urlResponse.data.updater_files_id,
        versionName: vname,
        releaseNotes,
      },
    });
    return {
      duplicate: false,
      filename_exists: false,
      updated: false,
      updater_files_id: urlResponse.data.updater_files_id,
    };
  },
});

// Host Stage Api. Manages stages
export type HostStageId = HostAgentStage['id'];

export type HostStageGetAllFilter = BaseGetAllFilter<HostMgmtId>;

export type HostStageMgmtApi = {
  resourceKey: string;
  getAll: () => Promise<PaginatedResponse<HostAgentStage>>;
  create: (item: Partial<HostAgentStage>) => Promise<HostAgentStage>;
  update: (
    id: number,
    item: Partial<HostAgentStage>
  ) => Promise<HostAgentStage>;
};

export const HostStageClient = (client: HttpClient): HostStageMgmtApi => ({
  resourceKey: 'agentstage',
  getAll: (): Promise<PaginatedResponse<HostAgentStage>> =>
    client.get({ url: '/agentstage' }),
  create: (model): Promise<HostAgentStage> =>
    client.post({
      url: `/agentstage`,
      payload: model,
    }),

  update: (id, item): Promise<HostAgentStage> =>
    client.put({ url: `/agentstage/`, payload: { ...item, stageId: id } }),
});
