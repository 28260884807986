/* eslint-disable camelcase */
import React, { useState } from 'react';
import Row from '@amzn/meridian/row';
import Tab, { TabGroup } from '@amzn/meridian/tab';

import { CorePage, CorePageTitle } from '$/components';
import { RootUri } from '$/features/routes';
import { HostMgmtListPanel } from './HostMgmtPanel';
import { HostStageListPanel } from './HostStage';
import { HostVersionAddPanel, HostVersionListPanel } from './HostVersion';

export function HostMgmtMainPage(): JSX.Element {
  const [currentTab, setCurrentTab] = useState('listAgent');
  return (
    <CorePage
      title="Host Agents Management"
      header={
        <Row widths="fill">
          <CorePageTitle title="Host Agent Control Panel" />
        </Row>
      }
      breadcrumbs={[{ title: 'Scheduling', href: RootUri }]}
    >
      <TabGroup value={currentTab} onChange={setCurrentTab}>
        <Tab value="listAgent">Host Agent List</Tab>
        {/* <Tab value="uploadFile">Upload File</Tab> */}
        <Tab value="listStage">Stage List</Tab>
        <Tab value="addVersion">Upload Version</Tab>
        <Tab value="listVersion">List Version</Tab>
      </TabGroup>
      {currentTab === 'listAgent' && <HostMgmtListPanel />}
      {currentTab === 'listStage' && <HostStageListPanel />}
      {currentTab === 'addVersion' && <HostVersionAddPanel />}
      {currentTab === 'listVersion' && <HostVersionListPanel />}
    </CorePage>
  );
}
