import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import Tooltip from '@amzn/meridian/tooltip';
import { Host } from '$/api';
import { HostSettingsUri } from '$/features/routes';

export type HostSettingsButtonProps = {
  host: Host;
};

export function HostSettingsButton({
  host,
}: HostSettingsButtonProps): JSX.Element {
  const history = useHistory();
  return (
    <Tooltip title={`${host.name} Settings`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(HostSettingsUri(host.id))}
      >
        <Icon tokens={cogTokens} />
      </Button>
    </Tooltip>
  );
}
