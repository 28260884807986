import React, { useMemo } from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { CoreDate } from '@amzn/dots-core-ui';
import { DeviceStatus } from '$/api';
import { CoreLabel, CoreLabelColor } from '$/components';

const colorMap: {
  names: DeviceStatus[];
  colors: CoreLabelColor;
}[] = [
  {
    names: ['idle', 'scheduled'],
    colors: 'blue',
  },
  {
    names: ['running', 'online'],
    colors: 'green',
  },
  {
    names: ['missing'],
    colors: 'darkOrange',
  },
  {
    names: ['charging'],
    colors: 'darkPurple',
  },
  {
    names: ['triage'],
    colors: 'darkGray',
  },
  {
    names: ['disabled'],
    colors: 'red',
  },
  {
    names: ['bootloader', 'stuck_in_boot', 'stuck_in_bootloader'],
    colors: 'lightPurple',
  },
  {
    names: ['unknown'],
    colors: 'tangerine',
  },
  {
    names: ['offline'],
    colors: 'lightOrange',
  },
  {
    names: ['downloading', 'agent_paused'],
    colors: 'lightGray',
  },
];

export const getDeviceStatusColors = (status: DeviceStatus): CoreLabelColor => {
  const [match] = colorMap.filter(
    (color) => color.names.indexOf(status) !== -1
  );
  return match?.colors ?? 'darkOrange';
};

export type DeviceStatusLableProps = {
  status?: DeviceStatus;
  lastModified?: Date;
};

export function DeviceStatusLabel({
  status = 'unknown',
  lastModified,
}: DeviceStatusLableProps): JSX.Element {
  const statusColor = useMemo(() => getDeviceStatusColors(status), [status]);
  return (
    <Row spacing="200">
      <CoreLabel color={statusColor} transform="uppercase" type="b100">
        <strong>{status.replace(/_/g, ' ')}</strong>
      </CoreLabel>
      {lastModified && (
        <Text>
          @ <CoreDate date={lastModified} />
        </Text>
      )}
    </Row>
  );
}
