import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CoreToasterProvider } from '@amzn/dots-core-ui';

import {
  HostsListUri,
  HostsRegisterUri,
  HostMgmtUri,
  DownloadsUri,
} from './routes';
import { HostListPage } from './HostListPage';
import { HostRegisterPage } from './HostRegisterPage';
import { DownloadsPage } from './DownloadsPage';
import { HostDetailPage } from './HostDetailPage';
import { HostEditPage } from './HostEditPage';
import { HostMgmtMainPage } from './HostMgmt';
import { HostSettingsPage } from './HostSettingsPage';

export type AppRouterProps = {
  isRootApp: boolean;
};

export function AppRouter({ isRootApp }: AppRouterProps): JSX.Element {
  return (
    <CoreToasterProvider>
      <Switch>
        <Route path={`${HostsRegisterUri}`}>
          <HostRegisterPage />
        </Route>
        <Route path={`${HostsListUri}/:id/settings`}>
          <HostSettingsPage />
        </Route>
        <Route path={`${HostsListUri}/:id/edit`}>
          <HostEditPage />
        </Route>
        <Route path={`${HostsListUri}/:id`}>
          <HostDetailPage />
        </Route>
        <Route path={`${HostsListUri}`}>
          <HostListPage />
        </Route>
        <Route path={`${DownloadsUri}`}>
          <DownloadsPage />
        </Route>
        <Route path={`${HostMgmtUri}`}>
          <HostMgmtMainPage />
        </Route>
        {isRootApp && <Redirect to={`${HostsListUri}`} />}
      </Switch>
    </CoreToasterProvider>
  );
}
