export const RootUri = '';

const createRegisterUri = (root: string) => `${root}/register`;
const createDownloadsUri = (root: string) => `${root}/downloads`;
const createDetailUri = (root: string) => (id: string | number) =>
  `${root}/${id}`;
const createEditUri = (root: string) => (id: string | number) =>
  `${createDetailUri(root)(id)}/edit`;
const createHostSettingsUri = (root: string) => (id: string | number) =>
  `${createDetailUri(root)(id)}/settings`;
const createListUri = (root: string) => `${root}/hosts`;

export const createDeviceDetailUri = (root: string, id: string | number) =>
  `${root}/devices/${id}`;
export const createPoolDetailUri = (root: string, id: string | number) =>
  `${root}/pools/${id}`;
export const createTestrunDetailUri = (root: string, id: string | number) =>
  `${root}/testruns/${id}`;

export const HostsUri = `${RootUri}`;
export const HostsRegisterUri = createRegisterUri(RootUri);
export const HostsListUri = createListUri(RootUri);
export const HostDetailUri = createDetailUri(HostsListUri);
export const HostEditUri = createEditUri(HostsListUri);
export const HostMgmtUri = `${RootUri}/hostmgmt`;
export const HostSettingsUri = createHostSettingsUri(HostsListUri);
export const DownloadsUri = createDownloadsUri(RootUri);
