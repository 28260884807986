import { HttpClient } from '@amzn/dots-core-ui';
import { Lab } from '../models';
import { BaseReadApi, BaseGetAllFilter, baseReadApiFactory } from '../baseApi';

export type LabId = Lab['id'];

export type LabGetAllFilter = BaseGetAllFilter<LabId> & {
  name?: string;
};

export type LabApi = BaseReadApi<LabId, Lab, LabGetAllFilter>;

export const LabClient = (client: HttpClient): LabApi => ({
  ...baseReadApiFactory(client, 'lab'),
});
