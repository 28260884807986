import { HttpClient } from '@amzn/dots-core-ui';
import { Device, DeviceMutation } from '../models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type DeviceId = Device['id'];

export interface DeviceGetAllFilter extends BaseGetAllFilter<DeviceId> {
  dsn?: string;
  devicePoolName?: string;
  createdBy?: string;
  type?: string;
  host?: string;
  status?: string;
}

export type DeviceApi = BaseApi<
  DeviceId,
  Device,
  DeviceGetAllFilter,
  Partial<DeviceMutation>
>;

export const DeviceClient = (client: HttpClient): DeviceApi => ({
  ...baseApiFactory(client, 'device'),
});
