import { useCallback } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryString } from '@amzn/dots-core-ui';

export const useIdParam = (): number => {
  const { id } = useParams<{ id: string }>();
  return Number(id);
};

export type SearchQueryParams = {
  page: number;
  q: string;
  filter: string;
};

export const useSearchParams = (): SearchQueryParams & {
  onSearchSubmit: (q: string, filter: string) => void;
  onSearchClear: () => void;
  onPageChange: (nextPage: number) => void;
} => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { params, stringify } = useQueryString<SearchQueryParams>({
    page: 1,
    q: '',
    filter: '',
  });
  const onSearchSubmit = useCallback(
    (q: string, filter?: string): void =>
      history.push(`${path}?${stringify({ q, filter })}`),
    [history, path, stringify]
  );
  const onSearchClear = (): void => onSearchSubmit('');
  const onPageChange = useCallback(
    (nextPage: number) =>
      history.push(
        `${path}?${stringify({
          ...params,
          page: nextPage,
        })}`
      ),
    [history, params, path, stringify]
  );
  return { ...params, onSearchSubmit, onSearchClear, onPageChange };
};
