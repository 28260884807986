import React from 'react';

import { CorePage, CorePageTitle } from '$/components';
import { RootUri } from '$/features/routes';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import { environment } from '$/environments';

import DownloadTokens from '@amzn/meridian-tokens/base/icon/download-large';
import Icon from '@amzn/meridian/icon';

export const DownloadsPage = (): JSX.Element => {
  /* eslint-disable prettier/prettier */
  return (
    <CorePage
      title="Downloads"
      header={<CorePageTitle title="Downloads" />}
      breadcrumbs={[{ title: 'Host Agents', href: RootUri }]}
    >
      <Alert type="informational">
        Download your host agent here!
        <Button
          type="icon"
          onClick={() =>
            window.open(
              `${environment.hostAgentServiceApiUrl}/package/download`,
              '_blank'
            )
          }
        >
          <Icon tokens={DownloadTokens}>Download</Icon>
        </Button>
      </Alert>
    </CorePage>
  );
};
