import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import { GetApi } from '../baseApi';

export const useGet = <TId, TModel>(
  resourceKey: string,
  get: GetApi<TId, TModel>,
  id: TId,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<unknown, Error, TModel, Array<any>>
): UseQueryResult<TModel, Error> =>
  useQuery([resourceKey, { id }], () => get(id), options);
