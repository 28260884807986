import React, { useMemo } from 'react';
import Tag from '@amzn/meridian/tag';
import { TagProps } from '@amzn/meridian/tag/tag';
import { TestrunStatus } from '$/api';

type TagType = TagProps['type'];

const typeMap: { status: TestrunStatus[]; type: TagType }[] = [
  { status: ['pending', 'downloading'], type: 'neutral' },
  { status: ['running'], type: 'theme' },
  { status: ['completed'], type: 'success' },
  { status: ['cancelled'], type: 'warning' },
  { status: ['timedout', 'error'], type: 'error' },
];

const lookupType = (status: TestrunStatus): TagType => {
  const [match] = typeMap.filter((item) => item.status.indexOf(status) !== -1);
  return match?.type ?? 'neutral';
};

export type TestrunStatusTagProps = {
  status: TestrunStatus;
};

export function TestrunStatusTag({
  status,
}: TestrunStatusTagProps): JSX.Element {
  const type = useMemo(() => lookupType(status), [status]);
  return <Tag type={type}>{status}</Tag>;
}
