import {
  CoreTabGroupSwitch,
  CoreTabRoute,
  useToaster,
} from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Host, useClient, useGet, useUpdate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { HostDetailUri, HostsUri, RootUri } from '$/features/routes';
import _ from 'lodash';
import { HostAgentSetting, HostSettingsForm } from './components';

// TODO: transition off dummy data to api.
const DUMMY_DATA: HostAgentSetting[] = [
  {
    settingName: 'Download Cache Days',
    sectionName: 'AGENT Settings',
    settingValue: 21,
  },
  {
    settingName: 'Min % Charge',
    sectionName: 'AGENT Settings',
    settingValue: undefined,
  },
  {
    settingName: 'KATS URL',
    sectionName: 'KATS Settings',
    settingValue: undefined,
  },
  {
    settingName: 'Kats Secret Management URL',
    sectionName: 'KATS Settings',
    settingValue: undefined,
  },
  {
    settingName: 'KBITS URL',
    sectionName: 'KBITS Settings',
    settingValue: undefined,
  },
];

export function HostSettingsPage(): JSX.Element {
  const id = useIdParam();
  const history = useHistory();
  const { openToast } = useToaster();
  const { hosts } = useClient();
  const {
    data: host,
    error,
    isError,
    isLoading,
  } = useGet(hosts.resourceKey, hosts.get, id);
  const { mutateAsync } = useUpdate(hosts.resourceKey, hosts.update);
  const onSubmit = useCallback(
    async (item: Partial<Host>): Promise<void> => {
      await mutateAsync({ id, item });
      openToast({
        title: 'Success!',
        message: `${host?.name} has been updated.`,
        type: 'success',
      });
      history.push(HostDetailUri(id));
    },
    [mutateAsync, id, openToast, host, history]
  );

  const settingTabs = _.groupBy(DUMMY_DATA, 'sectionName') as {
    string: HostAgentSetting[];
  };
  return (
    <CorePage
      title="Settings"
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[
        { title: 'Scheduling', href: RootUri },
        { title: 'Host Agents', href: HostsUri },
        { title: host?.name ?? '', href: HostDetailUri(id) },
      ]}
      header={<CorePageTitle title={host ? `${host?.name} Settings` : ''} />}
    >
      <CoreTabGroupSwitch>
        {Object.entries(settingTabs).map(([sectionName, settings]) => {
          return (
            <CoreTabRoute
              key={sectionName}
              label={sectionName}
              path={sectionName}
            >
              <HostSettingsForm
                onCancel={() => history.push(HostDetailUri(id))}
                onSubmit={onSubmit}
                host={host}
                settings={settings}
              />
            </CoreTabRoute>
          );
        })}
      </CoreTabGroupSwitch>
    </CorePage>
  );
}
