import { PaginatedResponse } from '@amzn/dots-core-ui';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { BaseGetAllFilter, GetAllApi } from '../baseApi';

export const useGetAll = <TId, TModel, TFilter extends BaseGetAllFilter<TId>>(
  resourceKey: string,
  getAll: GetAllApi<TId, TModel, TFilter>,
  filters: TFilter,
  options?: UseQueryOptions<unknown, Error, PaginatedResponse<TModel>>
): UseQueryResult<PaginatedResponse<TModel>, Error> => {
  const queryClient = useQueryClient();
  return useQuery([`${resourceKey}s`, filters], () => getAll(filters), {
    onSuccess(response: PaginatedResponse<TModel>): void {
      response.content.forEach((item) => {
        queryClient.setQueryData([resourceKey, { id: (item as any).id }], item);
      });
      if (options?.onSuccess) {
        options.onSuccess(response);
      }
    },
    keepPreviousData:
      options?.keepPreviousData === undefined ? true : options.keepPreviousData,
    staleTime: options?.staleTime === undefined ? 5000 : options.staleTime,
  });
};
