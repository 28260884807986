/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ColumnDefinition } from '@amzn/dots-core-ui';
import Checkbox from '@amzn/meridian/checkbox';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Tooltip from '@amzn/meridian/tooltip';
import Tag from '@amzn/meridian/tag';
import Row from '@amzn/meridian/row';
import { Testrun } from '$/api';
import { CoreDate, CoreDuration, CoreLabel } from '$/components';
import {
  createDeviceDetailUri,
  createTestrunDetailUri,
} from '$/features/routes';
import { environment } from '$/environments';
import Link from '@amzn/meridian/link/link';
import { TestrunStatusTag } from './TestrunStatusTag';
import { ToggleSetState, ToggleSetAction } from './types';

type TestrunCellProps = {
  testrun: Testrun;
};

function IdCell({ testrun }: TestrunCellProps): JSX.Element {
  const priorityMap: any = {
    '-1': { label: 'low', type: 'neutral' },
    '0': { label: 'medium', type: 'success' },
    '1': { label: 'high', type: 'error' },
  };
  const { label, type } = priorityMap[testrun.priority.toString()];
  return (
    <Row spacing="100">
      <Tooltip position="bottom" title={`Priority: ${label}`}>
        <Tag type={type} />
      </Tooltip>
      <Link href={createTestrunDetailUri(environment.schedulerUrl, testrun.id)}>
        {testrun.id}
      </Link>
    </Row>
  );
}

function DeviceCell({ testrun }: TestrunCellProps): JSX.Element {
  return (
    <Column spacing="300">
      {testrun.deviceDsn && (
        <Link
          href={createDeviceDetailUri(
            environment.schedulerUrl,
            testrun.deviceId
          )}
        >
          {testrun.deviceDsn}
        </Link>
      )}
      {testrun?.deviceType?.name && <Text>{testrun.deviceType.name}</Text>}
      {testrun?.hardwareConfiguration?.name && (
        <Text>{testrun.hardwareConfiguration.name}</Text>
      )}
    </Column>
  );
}

function BuildCell({ testrun }: TestrunCellProps): JSX.Element {
  return (
    <Column spacing="300">
      {testrun?.build?.type?.name && <Text>{testrun.build.type.name}</Text>}
      {testrun?.build?.version && <Text>{testrun.build.version}</Text>}
    </Column>
  );
}

function StatusCell({ testrun }: TestrunCellProps): JSX.Element {
  return (
    <Column spacing="300" alignmentHorizontal="center">
      <TestrunStatusTag status={testrun.status} />
      {testrun?.status === 'completed' && (
        <Row spacing="200">
          <CoreLabel color="green">{testrun?.PASS}</CoreLabel>
          <CoreLabel color="yellow">{testrun?.SKIP}</CoreLabel>
          <CoreLabel color="red">{testrun?.FAIL}</CoreLabel>
        </Row>
      )}
      {testrun?.status === 'cancelled' && testrun?.message && (
        <Row spacing="200">
          <CoreLabel color="yellow">{testrun?.message}</CoreLabel>
        </Row>
      )}
    </Column>
  );
}

export const TestrunColumnDefinitions: ColumnDefinition<Testrun>[] = [
  {
    id: 'id',
    header: 'ID',
    item: (testrun): JSX.Element => <IdCell testrun={testrun} />,
  },
  {
    id: 'testname',
    header: 'Test Name',
    item: (testrun): string => testrun.testName,
  },
  {
    id: 'status',
    header: 'Status',
    item: (testrun): JSX.Element => <StatusCell testrun={testrun} />,
  },
  {
    id: 'updated',
    header: 'Last Updated',
    item: ({ modified }): JSX.Element => <CoreDate date={modified} />,
  },
  {
    id: 'build',
    header: 'Build',
    item: (testrun): JSX.Element => <BuildCell testrun={testrun} />,
  },
  {
    id: 'device',
    header: 'Device',
    item: (testrun): JSX.Element => <DeviceCell testrun={testrun} />,
  },
  {
    id: 'runtime',
    header: 'Runtime',
    item: ({ runtime }): JSX.Element => <CoreDuration seconds={runtime} />,
  },
];

type SelectTestrunColDefinitionProps = {
  setToggleState: (t: ToggleSetAction) => void;
  toggleState: ToggleSetState;
};

export const getSelectableTestrunColumnDefinitions = ({
  toggleState,
  setToggleState,
}: SelectTestrunColDefinitionProps): ColumnDefinition<Testrun>[] => {
  return [
    {
      id: 'id',
      header: 'ID',
      item: (testrun): JSX.Element => (
        <Row>
          <Checkbox
            checked={toggleState[testrun.id] ?? false}
            onChange={(): void => {
              setToggleState({
                itemId: testrun.id,
                newState: !(toggleState[testrun.id] ?? false),
              });
            }}
          />
          <IdCell testrun={testrun} />
        </Row>
      ),
    },
    {
      id: 'testname',
      header: 'Test Name',
      item: (testrun): string => testrun.testName,
    },
    {
      id: 'status',
      header: 'Status',
      item: (testrun): JSX.Element => <StatusCell testrun={testrun} />,
    },
    {
      id: 'updated',
      header: 'Last Updated',
      item: ({ modified }): JSX.Element => <CoreDate date={modified} />,
    },
    {
      id: 'build',
      header: 'Build',
      item: (testrun): JSX.Element => <BuildCell testrun={testrun} />,
    },
    {
      id: 'device',
      header: 'Device',
      item: (testrun): JSX.Element => <DeviceCell testrun={testrun} />,
    },
    {
      id: 'runtime',
      header: 'Runtime',
      item: ({ runtime }): JSX.Element => <CoreDuration seconds={runtime} />,
    },
  ];
};
