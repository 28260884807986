import { HttpClient, PaginatedResponse } from '@amzn/dots-core-ui';

export type BaseGetAllFilter<TId> = {
  ids?: TId[];
  page?: number;
  pageSize?: number;
  fuzzySearch?: boolean;
};

export type Resource = {
  resourceKey: string;
};

export type GetAllApi<TId, TModel, TFilter extends BaseGetAllFilter<TId>> = (
  filters: TFilter
) => Promise<PaginatedResponse<TModel>>;

export type GetApi<TId, TModel> = (id: TId) => Promise<TModel>;

export type CreateApi<TModel, TMutationModel = Partial<TModel>> = (
  item: TMutationModel
) => Promise<TModel>;

export type UpdateApi<TId, TModel, TMutationModel = Partial<TModel>> = (
  id: TId,
  item: TMutationModel
) => Promise<TModel>;

export type DeleteApi<TId> = (itemId: TId) => Promise<void>;

export interface BaseReadApi<TId, TModel, TFilter extends BaseGetAllFilter<TId>>
  extends Resource {
  getAll: GetAllApi<TId, TModel, TFilter>;
  get: GetApi<TId, TModel>;
}

export interface BaseWriteApi<TId, TModel, TMutationModel = Partial<TModel>>
  extends Resource {
  create: CreateApi<TModel, TMutationModel>;
  update: UpdateApi<TId, TModel, TMutationModel>;
  delete: DeleteApi<TId>;
}

export type BaseApi<
  TId,
  TModel,
  TFilter extends BaseGetAllFilter<TId>,
  TMutationModel = Partial<TModel>
> = BaseReadApi<TId, TModel, TFilter> &
  BaseWriteApi<TId, TModel, TMutationModel>;

export const baseReadApiFactory = <
  TId,
  TModel,
  TFilter extends BaseGetAllFilter<TId>
>(
  client: HttpClient,
  endpoint: string
): BaseReadApi<TId, TModel, TFilter> => ({
  resourceKey: endpoint,

  getAll: (filters): Promise<PaginatedResponse<TModel>> =>
    client.get({ url: `/${endpoint}`, payload: filters }),

  get: (id): Promise<TModel> => client.get({ url: `/${endpoint}/${id}` }),
});

export const baseWriteApiFactory = <TId, TModel>(
  client: HttpClient,
  endpoint: string
): BaseWriteApi<TId, TModel> => ({
  resourceKey: endpoint,

  create: (model): Promise<TModel> =>
    client.post({
      url: `/${endpoint}`,
      payload: model as unknown as Record<string, unknown>,
    }),

  update: (id, model): Promise<TModel> =>
    client.put({ url: `/${endpoint}/${id}`, payload: { ...model, id } }),

  delete: (id): Promise<void> => client.delete({ url: `/${endpoint}/${id}` }),
});

export const baseApiFactory = <
  TId,
  TModel,
  TFilter extends BaseGetAllFilter<TId>,
  TMutationModel extends Partial<TModel>
>(
  client: HttpClient,
  endpoint: string
): BaseApi<TId, TModel, TFilter, TMutationModel> => ({
  ...baseReadApiFactory(client, endpoint),
  ...baseWriteApiFactory(client, endpoint),
});
