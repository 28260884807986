/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useReducer } from 'react';

import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import {
  HostMgmtSearchBar,
  HostMgmtTable,
  HostMgmtSubmitButton,
  HostChangeSetAction,
  HostChangeSetState,
} from './components';

const initialState = {};
const reducer = (
  state: HostChangeSetState,
  action: HostChangeSetAction
): HostChangeSetState => {
  return {
    ...state,
    [action.host_id]: {
      ...(action.host_id in state ? state[action.host_id] : {}), // Update pre-existing changes
      ...(action.versionId ? { versionId: action.versionId } : {}),
      ...(action.stageName != null ? { stageName: action.stageName } : {}),
      ...(action.updateMode != null ? { updateMode: action.updateMode } : {}),
      ...(action.comments != null ? { comments: action.comments } : {}),
      ...(action.autoUpdate != null ? { autoUpdate: action.autoUpdate } : {}),
    },
  };
};

export function HostMgmtListPanel(): JSX.Element {
  const [filter, setFilter] = useState(null as any);
  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

  return (
    <Column spacing="500">
      <Column spacingInset="400" type="outline">
        <Heading level={3}>Summary</Heading>
        <Divider />
        <HostMgmtSearchBar setFilter={setFilter} />
        {filter && (
          <HostMgmtTable
            filter={filter}
            setChangeSet={dispatch}
            state={state}
          />
        )}
        <HostMgmtSubmitButton state={state} />
        {/* Submit Button. Should be disabled if nothing is selected */}
      </Column>
    </Column>
  );
}
