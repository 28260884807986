/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import { CoreFormFooter } from '@amzn/dots-core-ui';
import { Host } from '$/api';
import { Controller, useForm } from 'react-hook-form';

export type HostSettingsFormProps = {
  host?: Host;
  hostname?: string;
  settings: HostAgentSetting[];
  onSubmit: (host: Partial<Host>) => void;
  onCancel: () => void;
};

export interface HostAgentSetting {
  settingName: string;
  sectionName: string;
  settingValue: string | number | undefined;
}

// TODO: handle onsubmit, form validation
export function HostSettingsForm({
  // host,
  // hostname,
  // onSubmit,
  settings,
  onCancel,
}: HostSettingsFormProps): JSX.Element {
  const { control, handleSubmit } = useForm({
    defaultValues: settings.reduce((defaultValues, setting) => {
      return {
        ...defaultValues,
        [setting.settingName]: setting.settingValue ?? '',
      };
    }, {} as any),
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        console.log(vs);
        // return onSubmit(payload);
      })}
    >
      <Column spacingInset="300" spacing="400" maxWidth="100%" width={650}>
        {settings.map((setting) => {
          return (
            <Controller
              key={setting.settingName}
              name={setting.settingName}
              control={control}
              render={({ field }) => (
                <Input label={setting.settingName} size="medium" {...field} />
              )}
            />
          );
        })}

        <CoreFormFooter
          isConfirmDisabled={false}
          isLoading={false}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
}
