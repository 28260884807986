import React from 'react';
import { Host, useClient } from '$/api';
import { InfiniteCoreApiTable } from '$/components/CoreApiTable';
import { DeviceColumnDefinitions } from '$/features/components/Devices/DeviceColumnDefinitions';
import { useLocalStorage } from '@amzn/dots-core-ui';

export type HostDevicesListProps = {
  host: Host;
};

export const HostDevicesList = ({
  host,
}: HostDevicesListProps): JSX.Element => {
  const { devices } = useClient();

  const [visibleColumnIds] = useLocalStorage(
    `${devices.resourceKey}-list-columns`,
    DeviceColumnDefinitions.map((col) => col.id)
  );

  return (
    <InfiniteCoreApiTable
      resourceKey={devices.resourceKey}
      visibleColumnIds={visibleColumnIds}
      getAll={devices.getAll}
      columns={DeviceColumnDefinitions}
      params={{ host: host.name }}
    />
  );
};
