import React from 'react';
import { Host, useClient } from '$/api';
import { InfiniteCoreApiTable } from '$/components/CoreApiTable';
import { TestrunColumnDefinitions } from '$/features/components/Testruns/TestrunColumnDefinitions';

export type HostTestrunsListProps = {
  host: Host;
};

export function HostTestrunsList({ host }: HostTestrunsListProps): JSX.Element {
  const { testruns } = useClient();

  return (
    <InfiniteCoreApiTable
      resourceKey={testruns.resourceKey}
      getAll={testruns.getAll}
      visibleColumnIds={TestrunColumnDefinitions.map((col) => col.id)}
      columns={TestrunColumnDefinitions}
      params={{ hostname: host.name }}
    />
  );
}
