import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SideMenu, {
  SideMenuTitle,
  SideMenuLink,
  SideMenuSpacer,
} from '@amzn/meridian/side-menu';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import helpIconTokens from '@amzn/meridian-tokens/base/icon/help';
import { InRole } from '@amzn/dots-core-ui';
import {
  DownloadsUri,
  HostMgmtUri,
  HostsListUri,
  HostsRegisterUri,
  HostsUri,
} from './routes';

export function AppNavigation({
  isMenuOpen,
}: {
  isMenuOpen: boolean;
}): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const pathStartsWith = (test: string): boolean =>
    location.pathname.substring(0, test.length) === test;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const menuOptions = (item: any) => {
    if (!item.role || InRole(item.role))
      return (
        <SideMenuLink
          key={item.title}
          href={item.path}
          onClick={history.push}
          selected={pathStartsWith(item.path)}
        >
          {item.title}
        </SideMenuLink>
      );
    return null;
  };
  return (
    <SideMenu width={225} open={isMenuOpen}>
      <SideMenuTitle href={HostsUri} onClick={history.push}>
        <Heading level={4}>Hosts</Heading>
      </SideMenuTitle>
      {[
        { title: 'Register', path: HostsRegisterUri },
        { title: 'Hosts', path: HostsListUri },
        { title: 'Downloads', path: DownloadsUri },
        { title: 'Admin', path: HostMgmtUri, role: 'sysOp' },
      ].map(menuOptions)}
      <SideMenuSpacer />
      <SideMenuLink
        href="https://wiki.labcollab.net/confluence/display/DOTS/Host+Agent+Redesign+Documentation"
        target="_blank"
      >
        <Row>
          <Icon tokens={helpIconTokens} />
          <span>User Guide</span>
        </Row>
      </SideMenuLink>
    </SideMenu>
  );
}
