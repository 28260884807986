import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { Host } from '$/api';
import { HostDetailUri } from '$/features/routes';
import { HostStatusTag } from './HostStatusTag';

export const HostColumnDefinitions: ColumnDefinition<Host>[] = [
  {
    id: 'hostname',
    header: 'Hostname',
    item: ({ id, name }): JSX.Element => (
      <CoreRouterLink href={HostDetailUri(id)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'status',
    header: 'Status',
    item: ({ status }): JSX.Element => <HostStatusTag status={status} />,
  },
  {
    id: 'version',
    header: 'Version',
    item: (host): string => host.version,
  },
  {
    id: 'owner',
    header: 'Owner',
    item: (host): string => host.owner,
  },
  // {
  //   id: 'lab',
  //   header: 'Location/Lab',
  //   item: (host): string =>
  //     host.lab?.name && host.location?.name
  //       ? `${host.location.name}/${host.lab.name}`
  //       : '',
  // },
];
