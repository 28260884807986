import React, { useMemo, useState } from 'react';
import {
  CoreTable,
  CoreTableSettingsButton,
  useLocalStorage,
} from '@amzn/dots-core-ui';
import Row from '@amzn/meridian/row';
import Alert from '@amzn/meridian/alert';
import Text from '@amzn/meridian/text';

import { useClient, useGetAll, HostMgmt, HostMgmtGetAllFilter } from '$/api';
import { getHostMgmtColumnDefinitions } from './HostMgmtColumnDefinitions';
import { HostChangeSetAction, HostChangeSetState } from './types';

export type TableProps = {
  filter: HostMgmtGetAllFilter;
  state: HostChangeSetState;
  setChangeSet: (t: HostChangeSetAction) => void;
};

export function HostMgmtTable({
  filter,
  state,
  setChangeSet,
}: TableProps): JSX.Element {
  const { hostmgmt } = useClient();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const columns = getHostMgmtColumnDefinitions({ setChangeSet, state });
  // Stolen from CoreSearchTable + some modifications

  const [visibleColumnIds, setVisibleColumnIds] = useLocalStorage(
    `${hostmgmt.resourceKey}-list-columns`,
    columns.map((col) => col.id)
  );

  const { data, error, isError, isFetching } = useGetAll(
    hostmgmt.resourceKey,
    hostmgmt.getAll,
    filter
  );

  const pageTotal = useMemo(() => {
    return Math.floor((data?.total ?? 0) / pageSize) + 1;
  }, [data, pageSize]);

  const tableData = useMemo(() => {
    if (data?.content) {
      // console.log(data?.content.length)
    }
    const currPage = page - 1;
    return data?.content
      ? data?.content.slice(currPage * pageSize, (currPage + 1) * pageSize)
      : [];
  }, [data, page, pageSize]);

  if (isError) {
    return (
      <Alert type="error" size="xlarge">
        {error?.message}
      </Alert>
    );
  }
  return (
    <CoreTable
      actions={
        <>
          <Row>
            {!isFetching && data?.total !== undefined && (
              <Text>{data.total} results</Text>
            )}
          </Row>
          <Row>
            <CoreTableSettingsButton
              columnDefinitions={columns}
              visibleColumnIds={visibleColumnIds}
              onColumnDefinitionsIdsChange={setVisibleColumnIds}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          </Row>
        </>
      }
      fixHeaderRows
      columnDefinitions={columns.filter(
        (col) => visibleColumnIds.indexOf(col.id) !== -1
      )}
      getItemId={(item: HostMgmt) => item.id}
      items={tableData}
      isLoading={isFetching}
      pagination={{
        onPageChange: setPage,
        currentPage: page,
        numberOfPages: pageTotal,
      }}
    />
  );
}
