import { HttpClient, ListResponse } from '@amzn/dots-core-ui';
import {
  Testrun,
  TestrunFile,
  TestrunQueueRequest,
  TestrunResult,
} from '../models';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';

export type TestrunId = Testrun['id'];

export interface TestrunGetAllFilter extends BaseGetAllFilter<TestrunId> {
  dsn?: string;
  status?: string;
  testName?: string;
  appName?: string;
  appVersion?: string;
  platformName?: string;
  platformVersion?: string;
  deviceType?: string;
  ruleId?: string;
  devicePoolId?: number;
  devicePoolName?: string;
  searchTerm?: string;
}

export interface TestrunApi
  extends BaseApi<TestrunId, Testrun, TestrunGetAllFilter> {
  getResults: (id: TestrunId) => Promise<ListResponse<TestrunResult>>;
  getDataFiles: (id: TestrunId) => Promise<ListResponse<TestrunFile>>;
  queue: (payload: TestrunQueueRequest) => Promise<TestrunId[]>;
  requeue: (
    id: TestrunId,
    useLatestVersion: boolean,
    sameDevice: boolean
  ) => Promise<number>;
  cancel: (ids: TestrunId[]) => Promise<void>;
  poolCancel: (poolName: string) => Promise<void>;
  getDownloadUrl: (logId: number) => Promise<string>;
}

const endpoint = 'testrun';

export const TestrunClient = (client: HttpClient): TestrunApi => ({
  ...baseApiFactory(client, endpoint),

  getResults: (id: TestrunId): Promise<ListResponse<TestrunResult>> =>
    client.get({ url: `/${endpoint}/${id}/result` }),

  getDataFiles: (id: TestrunId): Promise<ListResponse<TestrunFile>> =>
    client.get({ url: `/${endpoint}/${id}/file` }),

  queue: (payload: TestrunQueueRequest): Promise<TestrunId[]> =>
    client.post({
      url: `/${endpoint}/queue`,
      payload,
      formEncodePayload: false,
    }),

  requeue: (
    id: TestrunId,
    useLatestTestVersion: boolean,
    sameDevice: boolean
  ): Promise<number> =>
    client.put({
      url: `/${endpoint}/${id}/requeue`,
      payload: { useLatestTestVersion, sameDevice },
    }),

  // Potentially a bug here as well?
  cancel: (testrunIds: TestrunId[]): Promise<void> =>
    client.put({ url: `/${endpoint}/cancel`, payload: { testrunIds } }),

  poolCancel: (poolName: string): Promise<void> =>
    client.put({
      url: `/${endpoint}/cancel`,
      payload: { pool: poolName, cancelAmount: 1000, status: 'pending' },
    }),

  getDownloadUrl: (logId: number): Promise<string> =>
    client.get({ url: `/${endpoint}/${logId}/url` }),
});
