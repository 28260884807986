import React from 'react';
import Alert from '@amzn/meridian/alert';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import { useGet, useClient } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { useIdParam } from '$/hooks';
import { RootUri } from '$/features/routes';
import {
  CoreTabGroupSwitch,
  CoreTabRoute,
} from '@amzn/dots-core-ui/dist/components/CoreTabGroupSwitch';
import {
  HostStatusTag,
  HostLastUpdatedTag,
  HostEditButton,
  HostDevicesList,
  HostSettingsButton,
  HostTestrunsList,
} from './components';

export function HostDetailPage(): JSX.Element {
  const id = useIdParam();
  const { hosts } = useClient();
  const {
    data: host,
    error,
    isError,
    isLoading,
  } = useGet(hosts.resourceKey, hosts.get, id);
  return (
    <CorePage
      title={host?.name}
      isLoading={isLoading}
      isError={isError}
      error={error}
      breadcrumbs={[{ title: 'Host Agents', href: RootUri }]}
      header={
        <>
          <Row>
            {host?.status && <HostStatusTag status={host?.status} />}
            {host?.modified && (
              <HostLastUpdatedTag dateModified={host?.modified} />
            )}
          </Row>
          <Row widths={['fill', 'fit']}>
            <Column>
              {host?.name && <CorePageTitle title={host.name} />}
              <Column spacing="100">
                {host?.version && <Text>Version: {host.version}</Text>}
                {host?.owner && <Text>Owner: {host.owner}</Text>}
              </Column>
            </Column>
            {host && (
              <Row alignmentHorizontal="end">
                <HostEditButton host={host} />
                <HostSettingsButton host={host} />
              </Row>
            )}
          </Row>
        </>
      }
    >
      {host && (
        <CoreTabGroupSwitch>
          <CoreTabRoute key="details" label="Details" path="details">
            <Column spacing="500">
              <Column spacingInset="400" type="outline">
                <Heading level={3}>RSA Public Key</Heading>
                <Divider />
                {host?.rsaPublicKey ? (
                  <Column spacingInset="400" type="fill">
                    <Text tag="pre">
                      <pre>{host.rsaPublicKey}</pre>
                    </Text>
                  </Column>
                ) : (
                  <Alert>This host is not configured</Alert>
                )}
              </Column>
            </Column>
            {host?.lab?.name || host?.location?.name ? (
              <Column spacing="500">
                <Column spacingInset="400" type="outline">
                  <Heading level={3}>LMS</Heading>
                  <Divider />
                  <Column spacingInset="400">
                    {host?.location?.name && (
                      <Text>Location: {host.location.name}</Text>
                    )}
                    {host?.lab?.name && <Text>Lab: {host.lab.name}</Text>}
                  </Column>
                </Column>
              </Column>
            ) : (
              ''
            )}
          </CoreTabRoute>
          <CoreTabRoute key="devices" label="Devices" path="devices">
            <HostDevicesList host={host} />
          </CoreTabRoute>
          <CoreTabRoute key="testruns" label="Testruns" path="testruns">
            <HostTestrunsList host={host!} />
          </CoreTabRoute>
        </CoreTabGroupSwitch>
      )}
    </CorePage>
  );
}
