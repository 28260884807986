import React from 'react';
import { ColumnDefinition } from '@amzn/dots-core-ui';
import { HostAgentStage } from '$/api';
import { HostStageEditButton } from './HostStageEditButton';

export const HostStageColumnDefinitions: ColumnDefinition<HostAgentStage>[] = [
  {
    id: 'stageName',
    header: 'Name',
    item: (hoststage): string => hoststage.stageName,
  },
  {
    id: 'version',
    header: 'Version',
    item: (hoststage): string => hoststage.version,
  },
  {
    id: 'actions',
    header: 'Actions',
    item: (hoststage): JSX.Element => (
      <HostStageEditButton currHostStage={hoststage} />
    ),
  },
  // TODO add edit button
];
