import { useQueryString, useToaster } from '@amzn/dots-core-ui';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Host, useClient, useCreate } from '$/api';
import { CorePage, CorePageTitle } from '$/components';
import { HostDetailUri, HostsUri } from '$/features/routes';
import { HostForm } from './components';

export type HostQueryParams = {
  hostname: string;
  publicKey: string;
  version: string;
};

export const HostRegisterPage = (): JSX.Element => {
  const { params } = useQueryString<HostQueryParams>({
    hostname: '',
    publicKey: '',
    version: '',
  });
  const history = useHistory();
  const { openToast } = useToaster();
  const { hosts } = useClient();
  const { mutateAsync } = useCreate(hosts.resourceKey, hosts.create);
  const onSubmit = useCallback(
    async (host: Partial<Host>): Promise<void> => {
      const response = await mutateAsync(host);
      openToast({
        title: 'Success!',
        message: `The ${response?.name} host was registered`,
        type: 'success',
      });
      history.push(HostDetailUri(response.id));
    },
    [history, hosts, params, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Register Agent"
      breadcrumbs={[{ title: 'Host Agents', href: HostsUri }]}
      header={<CorePageTitle title="Register a New Host Agent" />}
    >
      <HostForm
        onSubmit={onSubmit}
        onCancel={() => history.push(HostsUri)}
        hostname={params.hostname}
        rsapub={params.publicKey}
        version={params.version}
      />
    </CorePage>
  );
};
