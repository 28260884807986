/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';

import { HostVersionSelect, HostStageSelect } from '$/components';
import { HostMgmt, HostVersion } from '$/api';
import { HostChangeSetAction, HostChangeSetState } from './types';

/*
This complicated class has to do with how we encode host agent update settings.

*/

const getUpdateMode = (item: HostMgmt): string => {
  if (item.desiredVersion === '-1') {
    return 'StayOnCurrent';
  }
  if (item.desiredVersion) {
    return 'ChooseVersion';
  }
  return 'ChooseStage';
};

const getVersionObject = (
  item: HostMgmt,
  state: HostChangeSetState
): HostVersion | undefined => {
  /**
   * state's VersionId is string, but the hostversion expects number
   * We'll check the cache's version id before the initial version id
   */
  const nVersionId = state[item.id]?.versionId
    ? Number(state[item.id].versionId)
    : Number(item.desiredVersion);
  if (nVersionId === -1 || Number.isNaN(nVersionId)) {
    // -1 means "stay on current"
    return undefined;
  }
  return {
    id: nVersionId,
    version: '??. Click to find out',
  };
};

const getStageName = (
  item: HostMgmt,
  state: HostChangeSetState
): string | undefined => {
  return state[item.id]?.stageName ? state[item.id].stageName : item.stageName;
};

type HostMgmtUpdaterSelectProps = {
  setChangeSet: (t: HostChangeSetAction) => void;
  state: HostChangeSetState;
  item: HostMgmt;
};

export function HostMgmtUpdaterSelect({
  setChangeSet,
  state,
  item,
}: HostMgmtUpdaterSelectProps): JSX.Element {
  const [updateMode, setUpdateMode] = useState(getUpdateMode(item));
  const changingUpdateMode = useCallback(
    (mode: string) => {
      setUpdateMode(mode);
      setChangeSet({
        host_id: item.id,
        updateMode: mode,
      });
    },
    [item.id, setChangeSet]
  );
  const setVersion = useCallback(
    (value: HostVersion | HostVersion[] | undefined) => {
      if (updateMode === 'ChooseVersion' && value && !Array.isArray(value)) {
        setChangeSet({
          host_id: item.id,
          versionId: value.id.toString(),
          updateMode: 'ChooseVersion',
        });
      }
    },
    [setChangeSet, item, updateMode]
  );
  const setStage = useCallback(
    (value: string | string[] | undefined) => {
      if (updateMode === 'ChooseStage' && value && !Array.isArray(value)) {
        setChangeSet({
          host_id: item.id,
          stageName: value,
          updateMode: 'ChooseStage',
        });
      }
    },
    [setChangeSet, item, updateMode]
  );
  return (
    <Row>
      <Select
        label="Pick Updater Mode"
        value={updateMode}
        onChange={changingUpdateMode}
        placeholder="Enter value..."
        width={300}
        size="xlarge"
      >
        <SelectOption value="StayOnCurrent" label="StayOnCurrent" />
        <SelectOption value="ChooseVersion" label="ChooseVersion" />
        <SelectOption value="ChooseStage" label="ChooseStage" />
      </Select>
      {updateMode === 'ChooseVersion' && (
        <HostVersionSelect
          value={getVersionObject(item, state)}
          onChange={setVersion}
        />
      )}
      {updateMode === 'ChooseStage' && (
        <HostStageSelect
          value={getStageName(item, state)}
          onChange={setStage}
        />
      )}
    </Row>
  );
}
