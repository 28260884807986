import React from 'react';
import Tag from '@amzn/meridian/tag';
import { TagProps } from '@amzn/meridian/tag/tag';
import { HostStatus } from '$/api';
import { formatDistanceToNow } from 'date-fns';

export const HostStatusTag = ({
  status,
}: {
  status: HostStatus;
}): JSX.Element => {
  let type: TagProps['type'] = 'neutral';
  if (status === 'online') {
    type = 'success';
  } else if (status === 'updating') {
    type = 'theme';
  } else if (status === 'deprecated') {
    type = 'error';
  }
  return <Tag type={type}>{status}</Tag>;
};

export const HostLastUpdatedTag = ({
  dateModified,
}: {
  dateModified: string;
}): JSX.Element => {
  const type: TagProps['type'] = 'neutral';
  const d = new Date(`${dateModified}Z`);
  return <Tag type={type}>{formatDistanceToNow(d, { addSuffix: true })}</Tag>;
};
