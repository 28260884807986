import React, { useContext, useMemo } from 'react';
import {
  createSearchClient,
  SearchApi,
  TokenCreator,
  useAuth,
} from '@amzn/dots-core-ui';
import {
  HostAgentServiceApi,
  SchedulingApi,
  createHostAgentServiceClient,
  createSchedulingClient,
} from './client';

export type ClientContext = {
  search: SearchApi;
  searchApiUrl: string;
  hostAgentServiceApiUrl: string;
  schedulingApiUrl: string;
} & HostAgentServiceApi & SchedulingApi;

export type ClientProviderProps = {
  children: React.ReactNode;
  createToken?: TokenCreator;
  searchApiUrl: string;
  hostAgentServiceApiUrl: string;
  schedulingApiUrl: string;
};

const ClientContext = React.createContext<ClientContext | undefined>(undefined);

export const ClientProvider = ({
  children,
  createToken,
  searchApiUrl,
  hostAgentServiceApiUrl,
  schedulingApiUrl,
}: ClientProviderProps): JSX.Element => {
  let ct = createToken;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ct = useAuth().createToken;
  } catch (error) {
    // ignored
  }
  if (ct === undefined) {
    throw new Error('no Token Creator provided');
  }
  const hostAgentServiceClient = createHostAgentServiceClient(
    hostAgentServiceApiUrl,
    createToken ?? ct
  );

  const schedulingClient = createSchedulingClient(
    schedulingApiUrl,
    createToken ?? ct
  );

  const search = createSearchClient(searchApiUrl, createToken ?? ct);

  const value = useMemo(
    () => ({
      ...hostAgentServiceClient,
      ...schedulingClient,
      search,
      searchApiUrl,
      hostAgentServiceApiUrl,
      schedulingApiUrl,
    }),
    [hostAgentServiceApiUrl, hostAgentServiceClient, search, searchApiUrl, schedulingApiUrl, schedulingClient]
  );
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = (): ClientContext => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};

ClientProvider.defaultProps = {
  createToken: null,
};
