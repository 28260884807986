/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import Theme from '@amzn/meridian/theme';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { useClient } from '$/api';
import { HostChangeSetState } from './types';

const HostMgmtTransform = (state: HostChangeSetState): any[] => {
  const t: any[] = [];
  Object.entries(state).forEach(
    ([hostId, { versionId, updateMode, autoUpdate, comments, stageName }]) => {
      const transformedItem: {
        [key: string]: string | number | boolean | undefined;
      } = {
        ...(typeof autoUpdate !== 'undefined' ? { autoUpdate } : {}),
        ...(comments ? { comments } : {}),
      };
      if (updateMode === 'StayOnCurrent') {
        transformedItem.versionId = -1;
      }
      if (updateMode === 'ChooseVersion') {
        transformedItem.versionId = versionId;
      }
      if (updateMode === 'ChooseStage') {
        transformedItem.labelName = stageName;
      }
      if (transformedItem) {
        transformedItem.hostId = hostId;
        t.push(transformedItem);
      }
    }
  );
  return t;
};

export type HostMgmtSubmitButtonProps = {
  state: HostChangeSetState;
};

export function HostMgmtSubmitButton({
  state,
}: HostMgmtSubmitButtonProps): JSX.Element {
  const { hostmgmt } = useClient();
  const { openToast } = useToaster();
  const onClick = useCallback(async (): Promise<void> => {
    const payload = HostMgmtTransform(state);
    // console.log(payload);
    hostmgmt.update(payload);
    openToast({
      title: 'Success!',
      message: `Update Success! The host metadata was updated`,
      type: 'success',
    });
  }, [state, hostmgmt, openToast]);
  return (
    <Theme palette="blue" mode="light">
      <Tooltip title="Submit Value" position="bottom">
        <CoreAsyncButton
          disabled={Object.keys(state).length === 0}
          type="tertiary"
          onClick={onClick}
          confirm={{
            title: `Submit value`,
            children: <Text>Submitting {JSON.stringify(state)} </Text>,
          }}
        >
          <Text>Submit Host Changes</Text>
        </CoreAsyncButton>
      </Tooltip>
    </Theme>
  );
}
