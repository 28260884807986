/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';

import { CoreModal, useToaster, useToggle } from '@amzn/dots-core-ui';
import { HostAgentStage, useClient } from '$/api';
import { HostStageForm } from './HostStageForm';

export type HostStageEditButtonProps = {
  currHostStage: HostAgentStage;
};

export function HostStageEditButton({
  currHostStage,
}: HostStageEditButtonProps): JSX.Element {
  const { openToast } = useToaster();
  const { hoststage } = useClient();
  const [isOpen, close, open] = useToggle();

  const onSubmit = useCallback(
    async (stage: Partial<HostAgentStage>): Promise<void> => {
      try {
        // TODO Add await after we add proper return type in the api
        hoststage.update(currHostStage.id, stage);
        // const response = hoststage.update(currHostStage.id, stage);
        // console.log(response);
        openToast({
          title: 'Success!',
          message: `Edited host agent stage.`,
          type: 'success',
        });
      } catch (e: any) {
        // console.error(e);
        const errorCode = e?.response?.status || '???';
        const errorResponse = JSON.stringify(e?.response?.data || {});
        openToast({
          title: 'Failed!',
          message: `${errorCode}: ${errorResponse}`,
          type: 'error',
        });
      }
    },
    [openToast, currHostStage.id, hoststage]
  );
  return (
    <>
      <Button onClick={open} type="tertiary">
        <Icon tokens={pencilIconTokens} />
      </Button>
      <CoreModal
        title="Edit a host agent stage"
        isOpen={isOpen}
        onClose={close}
      >
        <HostStageForm
          hostStage={currHostStage}
          onCancel={close}
          onSubmit={onSubmit}
        />
      </CoreModal>
    </>
  );
}
