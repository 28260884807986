/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  ColumnDefinition,
  CoreTable,
  CoreAsyncButton,
  HasId,
} from '@amzn/dots-core-ui';

import Row from '@amzn/meridian/row';
import Alert from '@amzn/meridian/alert';
import { BaseGetAllFilter, GetAllApi, useInfiniteGetAll } from '$/api';

export type CoreApiTableProps<
  TId,
  TModel extends HasId<number | string>,
  TFilter extends BaseGetAllFilter<TId>,
> = {
  resourceKey: string;
  visibleColumnIds: string[];
  setData?: (data: TModel[]) => void;
  getAll: GetAllApi<TId, TModel, TFilter>;
  columns: ColumnDefinition<TModel>[];
  params: ParamFilters;
};

export type PaginatedCoreApiTableProps<
  TId,
  TModel extends HasId<number | string>,
  TFilter extends BaseGetAllFilter<TId>,
> = CoreApiTableProps<TId, TModel, TFilter> & {
  onPageChange: (nextPage: number) => void;
};

export type ParamFilters = {
  [key: string]: string | number | boolean;
};

export function InfiniteCoreApiTable<
  TId,
  TModel extends HasId<number | string>,
  TFilter extends BaseGetAllFilter<TId>,
>({
  resourceKey,
  visibleColumnIds,
  getAll,
  setData = undefined,
  columns,
  params,
}: CoreApiTableProps<TId, TModel, TFilter>): JSX.Element {
  const {
    data,
    allData,
    error,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteGetAll(resourceKey, getAll, {
    ...params,
  } as unknown as TFilter);
  useEffect(() => {
    if (setData) {
      setData(allData ?? []);
    }
  }, [setData, allData]);
  if (isError) {
    return (
      <Alert type="error" size="xlarge">
        {error?.message}
      </Alert>
    );
  }
  return (
    <>
      <CoreTable
        fixHeaderRows
        columnDefinitions={columns.filter(
          (col) => visibleColumnIds.indexOf(col.id) !== -1
        )}
        getItemId={(item: TModel) => item.id}
        items={allData}
        isLoading={isLoading && !data}
      />
      {hasNextPage && (
        <Row alignmentHorizontal="center">
          <CoreAsyncButton
            type="tertiary"
            onClick={async (): Promise<void> => {
              await fetchNextPage();
            }}
          >
            Load More
          </CoreAsyncButton>
        </Row>
      )}
    </>
  );
}
