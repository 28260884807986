import React from 'react';
import Heading from '@amzn/meridian/heading';

export const CorePageTitle = ({ title }: { title: string }): JSX.Element => {
  return (
    <Heading level={1} type="d50">
      {title}
    </Heading>
  );
};
