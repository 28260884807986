import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import Tooltip from '@amzn/meridian/tooltip';
import { Host } from '$/api';
import { HostEditUri } from '$/features/routes';

export type HostEditButtonProps = {
  host: Host;
};

export const HostEditButton = ({ host }: HostEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`Edit ${host.name}`} position="bottom">
      <Button
        type="tertiary"
        onClick={() => history.push(HostEditUri(host.id))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};
