/* eslint-disable camelcase */
import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import Textarea from '@amzn/meridian/textarea';
import Toggle from '@amzn/meridian/toggle';

import { HostMgmt } from '$/api';
import { HostDetailUri } from '$/features/routes';
import { HostChangeSetAction, HostChangeSetState } from './types';
import { HostMgmtUpdaterSelect } from './HostMgmtUpdaterSelect';

type HostMgmtColDefinitionProps = {
  setChangeSet: (t: HostChangeSetAction) => void;
  state: HostChangeSetState;
};

export const getHostMgmtColumnDefinitions = ({
  setChangeSet,
  state,
}: HostMgmtColDefinitionProps): ColumnDefinition<HostMgmt>[] => {
  return [
    {
      id: 'host_name',
      header: 'Name',
      item: ({ id, hostName }): JSX.Element => (
        <CoreRouterLink href={HostDetailUri(id)}>{hostName}</CoreRouterLink>
      ),
    },
    {
      id: 'lab_name',
      header: 'Lab Name',
      item: ({ labName }): string => labName,
    },
    {
      id: 'current_version',
      header: 'Current Version',
      item: ({ currentVersion }): string => currentVersion,
    },
    {
      id: 'desired_version',
      header: 'Desired Version',
      item: (item): JSX.Element => (
        <HostMgmtUpdaterSelect
          setChangeSet={setChangeSet}
          state={state}
          item={item}
        />
      ),
    },
    {
      id: 'auto_update',
      header: 'Auto Update',
      item: ({ id, autoUpdate }): JSX.Element => (
        <Toggle
          checked={state[id]?.autoUpdate ?? autoUpdate}
          onChange={(t: boolean): void => {
            setChangeSet({
              host_id: id,
              autoUpdate: t,
            });
          }}
        />
      ),
    },
    {
      id: 'comments',
      header: 'Comments',
      item: ({ id, comments }): JSX.Element => (
        <Textarea
          value={state[id]?.comments ?? comments}
          onChange={(t: string): void => {
            setChangeSet({
              host_id: id,
              comments: t,
            });
          }}
        />
      ),
    },
  ];
};
