import React from 'react';

import { useClient } from '$/api';
import { CorePage, CorePageTitle, CoreSearchTable } from '$/components';
import { RootUri } from '$/features/routes';
import { HostColumnDefinitions } from './components';

const filters = [
  { key: 'name', label: 'Name', isDefault: true },
  { key: 'owner', label: 'Owner' },
  { key: 'status', label: 'Status' },
  { key: 'lab', label: 'Lab' },
  { key: 'location', label: 'Location' },
  { key: 'version', label: 'Version' },
];

export const HostListPage = (): JSX.Element => {
  const { hosts } = useClient();
  /* eslint-disable prettier/prettier */
  return (
    <CorePage
      title="Host Agents"
      header={<CorePageTitle title="Host Agents" />}
      breadcrumbs={[{ title: 'Host Agents', href: RootUri }]}
    >
      <CoreSearchTable
        resourceKey={hosts.resourceKey}
        getAll={hosts.getAll}
        resourceFilters={filters}
        columns={HostColumnDefinitions}
      />
    </CorePage>
  );
};
