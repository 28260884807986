/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Select, { SelectOption } from '@amzn/meridian/select';
import { SelectProps } from '@amzn/meridian/select/select';
import Text from '@amzn/meridian/text';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import {
  CorePageLoader,
  isNullOrWhitespace,
  useDebounce,
  useFilter,
  ValidationProps,
  withValidation,
} from '@amzn/dots-core-ui';

const isArray = <T,>(arg: T | T[]): arg is T[] => Array.isArray(arg);

export type CoreAsyncSelectProps<TItem> = Omit<
  SelectProps,
  'children' | 'searchQuery' | 'onSearch' | 'value' | 'onChange'
> & {
  value: TItem | TItem[] | undefined;
  errorMessage?: string;
  getItems: (query: string) => Promise<TItem[]>;
  getItemLabel: (item: TItem) => string;
  getItemValue: (item: TItem) => string | number;
  onChange: (value: TItem | TItem[] | undefined) => void;
};

function AsyncSelect<TItem>({
  label,
  value,
  getItems,
  getItemLabel,
  getItemValue,
  onChange,
  ...props
}: CoreAsyncSelectProps<TItem>): JSX.Element {
  const [query, setQuery] = useState('');
  const [queryKey, setQueryKey] = useState<string[]>([]);
  const [isQuerySettled, setIsQuerySettled] = useState(true);
  const [selectedValue, setSelectedValue] = useState(() => {
    if (value) {
      if (isArray(value)) {
        return value.map(getItemValue);
      }
      return getItemValue(value);
    }
    return undefined;
  });
  const [itemsCache, setItemCache] = useState<TItem[]>(() => {
    if (value) {
      if (isArray(value)) {
        return value;
      }
      return [value];
    }
    return [];
  });

  useDebounce(
    () => {
      setQueryKey([`select-search-${label}`, query]);
      setIsQuerySettled(true);
    },
    300,
    [query]
  );

  const { data, error, isSuccess, isFetching, isError } = useQuery({
    queryKey,
    queryFn: () => getItems(query),
  });

  useEffect(() => {
    setItemCache((prev) =>
      (data ?? [])
        .concat(prev)
        .filter(
          (val, index, self) =>
            self.findIndex(
              (inner) => getItemValue(val) === getItemValue(inner)
            ) === index
        )
    );
  }, [data, getItemLabel, getItemValue]);

  const hasQuery = useMemo(
    () => !isNullOrWhitespace(query) && isQuerySettled,
    [query, isQuerySettled]
  );

  const getItemByValue = useCallback(
    (val) => {
      if (isArray(val)) {
        return itemsCache.filter(
          (item) => val.indexOf(getItemValue(item)) !== -1
        );
      }
      return itemsCache.filter((item) => getItemValue(item) === val)[0];
    },
    [itemsCache, getItemValue]
  );

  const onSearch = useCallback((q) => {
    setQuery(q);
    setIsQuerySettled(false);
  }, []);

  const onSelectedValueChange = useCallback(
    (v) => {
      setSelectedValue(v);
      onChange(getItemByValue(v));
    },
    [getItemByValue, onChange]
  );

  // multi-selects add tags below the input element to show the selected values
  // we need to invoke on change when a tag is closed
  const closeOption = useCallback(
    (option) => {
      if (isArray(value)) {
        onSelectedValueChange(
          value
            .filter((v) => getItemValue(option) !== getItemValue(v))
            .map(getItemValue)
        );
      }
    },
    [getItemValue, onSelectedValueChange, value]
  );

  // todo: how to handle rendering over 200 elements?
  // meridian is having trouble rendering lists much larger than 200 elements
  // probs need to virtualizing the list or creating something like a cursor
  const filteredItems = useFilter(itemsCache, query, (item) =>
    getItemLabel(item)
  ).slice(0, 200);

  return (
    <>
      <Select
        {...props}
        label={label}
        onChange={onSelectedValueChange}
        value={selectedValue}
        onSearch={onSearch}
        searchQuery={query}
        popoverMaxHeight={300}
      >
        {isError && (
          <Alert type="error" size="large">
            {`${error}`}
          </Alert>
        )}
        {filteredItems.length && !isFetching ? (
          filteredItems
            .map((item) => (
              <SelectOption
                key={getItemValue(item)}
                label={getItemLabel(item)}
                value={getItemValue(item)}
              />
            ))
            .concat(
              <SelectOption
                key="000"
                label="Start typing to see more results"
                value=""
                disabled
              />
            )
        ) : (
          <Column alignmentVertical="center" spacing="300" spacingInset="400">
            {!hasQuery && !itemsCache.length && !isFetching && (
              <Text>Start typing to see more results</Text>
            )}
            {hasQuery && isSuccess && data && !data.length && (
              <Text>No results for &quot;{query}&quot;</Text>
            )}
            {(isFetching || !isQuerySettled) && <CorePageLoader />}
          </Column>
        )}
      </Select>
      {isArray(value) && (
        <Row wrap="down">
          {value.map((option) => {
            return (
              <Tag
                key={getItemValue(option)}
                type="theme"
                onClose={(): void => closeOption(option)}
              >
                {getItemLabel(option)}
              </Tag>
            );
          })}
        </Row>
      )}
    </>
  );
}

export const CoreAsyncSelect: <TItem>(
  props: ValidationProps<CoreAsyncSelectProps<TItem>>
) => JSX.Element = <TItem,>(
  props: ValidationProps<CoreAsyncSelectProps<TItem>>
): JSX.Element =>
  withValidation<typeof AsyncSelect, CoreAsyncSelectProps<TItem>>(AsyncSelect)(
    props
  );
