/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';

// import { useIdParam } from '$/hooks';

import { useForm, CoreFormFooter } from '@amzn/dots-core-ui';
// import { useClient } from '$/api';

import { LabSelect } from '$/components';

export type SearchBarProps = {
  setFilter: (t: any) => void;
};

export function HostMgmtSearchBar({ setFilter }: SearchBarProps): JSX.Element {
  // const { hostmgmt } = useClient();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    labs: {
      initialValue: [],
    },
  });
  // Needed a select of lab vs host id vs labels.
  const onSubmit = useCallback(async (): Promise<void> => {
    setFilter({
      labs: values.labs,
      page: 0,
      pageSize: 0,
    });
  }, [values, setFilter]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <LabSelect
          value={values.labs}
          onChange={handlers.labs}
          errorMessage={errors.labs}
          validationState={states.labs}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={() => {}}
        />
      </Column>
    </form>
  );
}
