/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  ValidationProps,
  isNullOrWhitespace,
  CoreFilterableSelect,
  CoreFilterableSelectProps,
} from '@amzn/dots-core-ui';

import Alert from '@amzn/meridian/alert';
import { useClient, HostVersion } from '$/api';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

export type HostVersionSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<HostVersion>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export function HostVersionSelect(props: HostVersionSelectProps): JSX.Element {
  const { hostversion } = useClient();

  const getTypes = async (query: string): Promise<HostVersion[]> => {
    if (isNullOrWhitespace(query)) {
      return [];
    }
    const { content } = await hostversion.getAll({ page: 0, pageSize: 0 });
    return content;
  };

  return (
    <CoreAsyncSelect
      {...props}
      label="Host Versions(s)"
      getItems={getTypes}
      getItemLabel={(item): string => item.version}
      getItemValue={(item): number => item.id}
    />
  );
}

export type HostVersionNameSelectProps = Pick<
  ValidationProps<CoreFilterableSelectProps>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export function HostVersionNameSelect({
  validationState,
  ...rest
}: HostVersionNameSelectProps): JSX.Element {
  const { hostversion } = useClient();
  const { data, isLoading, isError, error } = useQuery<string[], Error>(
    'agentstage',
    async () => {
      const response = await hostversion.getAll({ page: 0, pageSize: 0 });
      return response.content.map((item) => item.version);
    }
  );
  const items = useMemo(() => data ?? [], [data]);
  if (isError) {
    return (
      <Alert type="error" title="Failed to get Host Agent Stages">
        {error?.message}
      </Alert>
    );
  }
  return (
    <CoreFilterableSelect
      {...rest}
      label="Host Version Names"
      items={items}
      disabled={isLoading}
      validationState={isLoading ? 'validating' : validationState}
    />
  );
}
