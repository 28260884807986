import React from 'react';
import { Buffer } from 'buffer';

import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import Button from '@amzn/meridian/button';
import DownloadTokens from '@amzn/meridian-tokens/base/icon/download-large';
import Icon from '@amzn/meridian/icon';
import { ColumnDefinition } from '@amzn/dots-core-ui';

import { useClient, HostVersion } from '$/api';
import { CoreSearchTable } from '$/components';
import { environment } from '$/environments';

export const HostVersionColumnDefinitions: ColumnDefinition<HostVersion>[] = [
  {
    id: 'id',
    header: 'Id',
    item: (hostversion): number => hostversion.id,
  },
  {
    id: 'version',
    header: 'Version',
    item: (hostversion): string => hostversion.version,
  },
  {
    id: 'download',
    header: 'download',
    item: (hostversion): JSX.Element => (
      <Button
        type="icon"
        onClick={() =>
          window.open(
            // https://api.scheduler.dots.lab126.a2z.com/api/v3/new_updates/version_data/download
            `${environment.rootSchedulingApiUrl}/api/v3/new_updates/version_data/download/${Buffer.from(hostversion.version, 'binary').toString('base64')}`,
            '_blank'
          )
        }
      >
        <Icon tokens={DownloadTokens}>Download</Icon>
      </Button>
    ),
  },
  // TODO add edit button
];

const filters = [
  { key: 'versionName', label: 'Name', isDefault: true },
  { key: 'id', label: 'Id' },
];

export function HostVersionListPanel(): JSX.Element {
  const { hostversion } = useClient();
  return (
    <Column spacing="500">
      <Column spacingInset="400" type="outline">
        <Heading level={3}>Host Stage</Heading>
        <Divider />
        <CoreSearchTable
          resourceKey={hostversion.resourceKey}
          getAll={hostversion.getAll}
          resourceFilters={filters}
          columns={HostVersionColumnDefinitions}
        />
      </Column>
    </Column>
  );
}
